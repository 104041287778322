import React, {useState} from 'react'
import EventsNavbar from '../components/EventsComponents/EventsNavbar'
import EventsSidebar from '../components/EventsComponents/EventsSidebar';
import InfoSection from '../components/InfoSection';
//import { eventsObjOverview, eventsObjCollaborations, eventsObjProblemSolving, eventsObjSocial, eventsObjCharity} from '../components/InfoSection/Data';
import Footer from '../components/Footer';
import SmallInfoSection from '../components/SmallInfoSection';

const Events = () => {
  
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };
  
    return (
    <>
        <EventsSidebar isOpen={isOpen} toggle={toggle}/>
        <EventsNavbar toggle={toggle}/>
        <div style={{background: "black", height: "80px"}}></div>        
        <Footer></Footer>
    </>
  )
}

export default Events