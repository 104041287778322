export const homeObjOne = {
    id: 'services',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Our Services',
    headline: 'Empowering Your Vision',
    description: "Unlock the potential of tomorrow with Exonix's comprehensive suite of cutting-edge services. Our commitment goes beyond mere solutions; we are dedicated to empowering your unique vision. From AI security audits to organizational adoption consulting and AI cyber security testing, we navigate the intricate landscape of technology, liberating your ideas and propelling your innovation forward. Discover how Exonix transforms challenges into opportunities, providing tailored services that align seamlessly with your vision for the future.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/abstract_fibre.avif'),
    alt: 'Info Image 1',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: '',
    imgborder: false
};

export const homeObjTwo = {
    id: 'solutions',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Solutions',
    headline: 'Delivering Results',
    description: "At Exonix, we don't just provide solutions; we deliver measurable results that transform challenges into success stories. Our solutions are meticulously crafted to address your unique needs, ensuring a strategic and impactful approach. From innovative problem-solving to tailored strategies, Exonix is your partner in achieving tangible outcomes.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/testing_blue.avif'),
    alt: 'Info Image 2',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: '',
    imgborder: true
};

export const homeObjThree = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Us',
    headline: 'Discover Exonix',
    description: "As you immerse yourself in the dynamic journey of Exonix, we invite you to become a part of our narrative. Discover how our unwavering commitment to excellence, passion for innovation, and a dedicated team uniquely position us in the technological landscape. Now that you know our story, we're eager to learn yours. Let's embark on a collaborative journey—where your vision meets our expertise. Contact us today, and let's shape the future together.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/silhouette.avif'),
    alt: 'Info Image 3',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: '',
    imgborder: false
};

export const teamObjAbout = {
    id: 'about us',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Us',
    headline: "Meet The Team",
    description: 'Description.',
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/placeholder.png'),
    alt: 'Team Image 1',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: ''
};

export const teamObjHistory = {
    id: 'history',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Club History',
    headline: 'Origins of UQ Fintech',
    description: 'Description.',
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/placeholder.png'),
    alt: 'Team Image 3',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: ''
};

export const teamMaxG = {
    id: 'MaxG',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Chief Executive Officer',
    headline: 'Max Gadd',
    description: "My journey within the machine learning industry has revolved around exploring technology's pivotal role in engineering discipline. I am proud to have founded Exonix, where we work on challenging projects that impact a diverse range of Australian industries.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/execs/Max.JPG'),
    alt: 'Max Gadd',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const eventsObjSocial = {
    id: 'social events',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Events',
    headline: 'Social Events',
    description: "Experience the vibrant side of fintech with our social events. Our Industry Barbecues offer the opportunity to network with professionals in a relaxed outdoor setting, while Social Poker nights provide a fun, casual atmosphere to meet like-minded peers. And as each semester winds down, our end-of-semester drinks bring members together to celebrate achievements and build lasting connections in a more informal setting.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/placeholder.png'),
    alt: 'Events Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const eventsObjCollaborations = {
    id: 'collaboration events',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Events',
    headline: 'Collaboration Events',
    description: "We believe in fostering connections beyond our boundaries. Through collaboration with various UQ clubs and societies, we've co-created unique events like the inaugural Science Ball, energetic pub crawls, and exciting sporting competitions. These events unite students from diverse disciplines, enhancing the fintech experience by bringing together a spectrum of knowledge and expertise.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/placeholder.png'),
    alt: 'Events Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const eventsObjCharity = {
    id: 'charity events',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Events',
    headline: 'Charity Events',
    description: "We are dedicated to making a positive impact in the community. The UQ Fintech Society actively participates in, and encourages its members to support, various charity initiatives. These endeavors exemplify our commitment to giving back and making a difference beyond the fintech landscape.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/placeholder.png'),
    alt: 'Events Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

