import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import ContactUs from '../components/ContactUs';

const ContactUsPage = () => {

  return (
    <>
        <ScrollToTop />
        <ContactUs />
    </>
  )
}

export default ContactUsPage