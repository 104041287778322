import React from 'react';
import {FaBars} from 'react-icons/fa';
import Logo from '../../images/logo.png';
import {Nav, NavbarContainer, NavLogo, MobileIcon, NavBtn, NavBtnLink} 
    from './NavbarElements';
import { UserAuth } from '../../context/AuthContext';

const NavbarDashboard = ({toggle}) => {

    const {logOut} = UserAuth();

    const handleSignOut = async () => {
        try {
            await logOut()
        } catch (error) {
            console.log(error)
        }
    }

    return (
            <Nav>
                <NavbarContainer>
                    <NavLogo to='/'>
                        <img src={Logo} alt="Logo" style={{ width: '50px', padding: '5px',}}></img>
                        Exonix 
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavBtn>
                        <NavBtnLink onClick={handleSignOut}>
                            Sign Out
                        </NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
    );
};

export default NavbarDashboard