import React, {useState} from 'react'
import Navbar from '../components/SponsorComponents/SponsorNavbar'
import Sidebar from '../components/SponsorComponents/SponsorSidebar';
import InfoSection from '../components/InfoSection';
//import {sponsorObjbecome, sponsorObjsponsors, sponsorObjSIG, sponsorObjOptiver, sponsorObjVivcourt, sponsorObjIMC, sponsorObjMantelGroup, sponsorObjAkuna, sponsorObjJaneStreet, sponsorObjRedBull} from '../components/InfoSection/Data';
import Footer from '../components/Footer';
import SmallInfoSection from '../components/SmallInfoSection';

const Sponsors = () => {
  
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };
  
    return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        <div style={{background: "black", height: "80px"}}></div>
        <Footer></Footer>
    </>
  )
}

export default Sponsors