import styled from "styled-components";

export const CustomFooter = styled.footer `
    background-color: #000000;
    height: auto;
    padding: 10px;
    border-top: 1px solid black;
`;

export const FooterP = styled.p`
    color: white;
    text-align: center;
    font-size: 14px;
`;

export const Socials = styled.ul `
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    width: 20%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto; 
    list-style-type: none;
`

export const SocialLinks = styled.svg `
    &:hover {
        fill: white;
    }
`;