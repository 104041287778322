// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAiN1q0dW6JM1wjTwPg-cljLN1DMeZ7unk",
  authDomain: "exonixtest.firebaseapp.com",
  projectId: "exonixtest",
  storageBucket: "exonixtest.appspot.com",
  messagingSenderId: "710592926779",
  appId: "1:710592926779:web:06506850ec9ca0464a781e",
  measurementId: "G-KGFWRYVLFN"
};



// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const database = getFirestore(firebaseApp);