import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarWrapper, SidebarLink, SidebarRoute, SideBtnWrap} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="services" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    Services
                </SidebarLink>
                <SidebarLink to="solutions" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    Solutions
                </SidebarLink>
                <SidebarLink to="about" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    About
                </SidebarLink>
                <SideBtnWrap>
                    <SidebarRoute to="/contact_us" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                        Contact Us
                    </SidebarRoute>
                </SideBtnWrap>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar