import styled from 'styled-components';

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#FFFFFF' : '#000000')};
    padding:120px;
    height: 100vh;
    @media screen and (max-width: 1366px) {
        height: 100%;
        padding: 10px 0;
    }
    display: flex;
    align-items: center; 
    justify-content: center;
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    jusitfy-content: center;
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 1366px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 30px;
    grid-area: col1;
`;
export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 30px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    text-align: left;
    padding-top: 0;
    padding-bottom: 15px;
    max-width: 80%;

    @media screen and (max-width: 1366px) {
        max-width: 100%;
    }
`;

export const TopLine = styled.p`
    color: #8d5bee;
    font-size: 20px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
    margin-top: 30px
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 64px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#FFFFFF' : '#000000')};
    @media screen and (max-width: 768px) {
        font-size: 32px;
    }
    @media screen and (max-width: 420px) {
        font-size: 18px;
    }
`;

export const Subtitle = styled.p`
    margin-bottom: 35px;
    font-size: 24px;
    color: ${({lightTextDesc}) => (lightTextDesc ? '#FFFFFF' : '#000000')};
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
    @media screen and (max-width: 420px) {
        font-size: 12px;
    }
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    height: 80%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 0px 0;
    padding-right: 0;
    border: ${({imgborder}) => (imgborder ? '5' : '0')}px solid ${({lightBorder}) => (lightBorder ? '#FFFFFF' : '#000000')};
`;

export const A = styled.a`
    text-decoration: none;
`
