import './App.css';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import Home from './pages';
import SignInPage from './pages/signin';
import { AuthContextProvider } from './context/AuthContext';
import User from './pages/user';
import Protected from './components/Protected';
import SignUpPage from './pages/signup';
import Team from './pages/team';
import Sponsors from './pages/sponsors';
import Events from './pages/events';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import ContactUsPage from './pages/contact';

const ANALYTICS_ID = 'G-JP5Y51E4TT' // OUR GOOGLE ANALYTICS ID (fintech :skull emoji:)
ReactGA.initialize(ANALYTICS_ID)

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
    <Router>
      <AuthContextProvider>
      <Routes>
        <Route path='/' element={<Home />} exact />
        <Route path='/signin' element={<SignInPage />} />
        <Route path='/signup' element={<SignUpPage />} />
        <Route path='/team' element={<Team />} />
        <Route path='/sponsors' element={<Sponsors />} />
        <Route path='/events' element={<Events />} />
        <Route path='/contact_us' element={<ContactUsPage />} />
        <Route path='/user' element={
          <Protected>
            <User />
          </Protected>
        } />
      </Routes>
      </AuthContextProvider>
    </Router>
    </>
  );
}

export default App;
