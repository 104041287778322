import React, {useState} from 'react';
import Video from '../../videos/hero1080p.mp4';
import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, ArrowForward, ArrowRight, HeroBtnWrapper, A, Content} from './HeroElements';
import { Button } from '../ButtonElement';
import Socials3D from '../Socials3D';
import {Link as LinkS} from 'react-scroll';

const HeroSection = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted playsInline src={Video} type='video/mp4' />
            </HeroBg>
            <Content>
            <HeroContent>
                <HeroH1>
                    Exonix Australia
                </HeroH1>
                <HeroBtnWrapper>
                <LinkS to="services" style={{transition: "0.2s ease-in-out"}} smooth={true}>
                    <Button onMouseEnter={onHover} onMouseLeave={onHover}>
                    Get Started {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </LinkS>
                </HeroBtnWrapper>
            </HeroContent>
            </Content>
        </HeroContainer>
    )
}

export default HeroSection