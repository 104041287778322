import React, {useState, useEffect} from 'react';
import {Button} from "../ButtonElement";
import Icon from "./Icon";
import { StyledInput} from "./Input";
import Logo from '../../images/logo.png';
import { FaGoogle} from "react-icons/fa";
import { WelcomeText, InputContainer, ButtonContainer, LoginWith, IconsContainer, LogoHeader,
    ForgotPassword, MainContainer, BackgroundDiv, ArrowForward, ArrowRight, BorderContainer,
  ImgWrap, Img} from "./ContactUsComponents";
import background from "../../images/loginGradient.jpg"
import { UserAuth } from '../../context/AuthContext';
import { Link as LinkR} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import Alert from "react-bootstrap/Alert";
import { firebaseAuth } from "../..//firebaseServices";
import image from "../../images/card.png";

function ContactUs() {

  return (
    <BackgroundDiv style={{ backgroundImage: `url(${background})` }}>
    <MainContainer>
      <BorderContainer>
      <LinkR to="/" style={{textDecoration: "none"}}>
      <LogoHeader>
        <img src={Logo} alt="Logo" style={{ width: '1.75rem', paddingRight: '5px',}}></img>
        <p style={{marginRight: '5px'}}> Exonix</p>
      </LogoHeader>
      </LinkR>

      <WelcomeText>Contact Information</WelcomeText>

      <ImgWrap>
        <Img src={image} alt="Business Card" lightBorder={true} imgborder={true}/>
      </ImgWrap>

      
      </BorderContainer>
        <ForgotPassword>
          Thank you for your interest!
        </ForgotPassword>
      </MainContainer>
    </BackgroundDiv>
  );
}

export default ContactUs;